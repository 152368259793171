import { CheckIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { EditIcon, PlusIcon } from "../../assets/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { jointService, jobService, userService } from "../../services";
import { useAlert, positions } from "react-alert";
import { Loading, UpdateJointModal, Select } from "../../components";
import Dashboard from "../Dashboard/Dashboard";
import ChooseNodeModal from "./ChooseNodeModal";
import QuickAddModal from "./QuickAddModal";
import { useStore } from "../../hooks/useStores";

const CreateNewJobStep2 = ({
  selectedJob,
  setNewJoint,
  handleJobs,
  jobInfo,
  lots,
  setNewLot,
  setNewJob,
}) => {
  const [selectedCasing, setSelectedCasing] = useState({});
  const { t } = useTranslation();
  const { auth } = useStore();
  const [joints, setJoints] = useState([]);
  const [editJointModal, setEditJointModal] = useState(false);
  const [isMap, setIsMap] = useState(false);
  const [coordinates, setCoordinates] = useState([39.3851, 35.1734]);
  const [loading, setLoading] = useState(false);
  const [selectedJoint, setSelectedJoint] = useState({});
  const [chooseNodeModal, setChooseNodeModal] = useState(false);
  const [quickAddModal, setQuickAddModal] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState("");
  const [wMuds, setWMuds] = useState([]);
  const alert = useAlert();
  const tableHeadItems = [
    { label: t("job:jointNo"), value: "JointNo" },
    { label: t("job:centralizer"), value: "centralizer" },
    { label: t("job:materialSpecific"), value: "lotNo" },
    { label: t("job:casingNo"), value: "CasingNo" },
    { label: t("casing:casingName"), value: "CasingName" },
    { label: t("job:length"), value: "length" },
    { label: t("job:totalLength"), value: "TotalLength" },
    { label: t("job:materialTopEndDepth"), value: "TopEndDepth" },
    { label: t("job:wMud"), value: "Wmud" },
    { label: t("job:totalWMud"), value: "WmudTotal" },
    { label: "", value: "" },
  ];
  const createLotName = (b) => {
    const found = lots.filter((c, i) => {
      return c?.lotNo == b?.lotNo;
    })[0];
    return `${found?.size}" ${found?.grade} ${found?.weight} lb/ft ${found?.typeConn}`;
  };
  const casingItems = lots?.map((lot, i) => {
    return {
      value: lot.lotNo,
      label: `Lot ${lot.lotNo}: ${lot?.size}" ${lot.grade} ${lot.weight} lb/ft ${lot.typeConn}`,
    };
  });
  const upperTable = [
    {
      label: t("job:lease"),
      value: jobInfo?.lease ? jobInfo?.lease : "---",
    },
    {
      label: t("job:rig"),
      value: jobInfo?.rig ? jobInfo?.rig : "---",
    },
    {
      label: t("job:well"),
      value: jobInfo?.well ? jobInfo?.well : "---",
    },
    {
      label: t("job:companyRepresentative"),
      value: jobInfo?.companyRep ? jobInfo?.companyRep : "---",
    },

    {
      label: t("job:firstTechnician"),
      value: jobInfo?.firstTech ? jobInfo?.firstTech : "---",
    },
    {
      label: t("job:mudWeight"),
      value: jobInfo?.ppcf ? jobInfo?.ppcf : "---",
    },
    {
      label: t("job:flotation"),
      value: jobInfo?.flotation ? jobInfo?.flotation : "---",
    },
    {
      label: t("job:wellDepth"),
      value: jobInfo?.wellDepth ? jobInfo?.wellDepth : "---",
    },
    {
      label: t("job:ratHole"),
      value: jobInfo?.ratHole ? jobInfo?.ratHole : "---",
    },
    {
      label: t("job:secondTechnician"),
      value: jobInfo?.secondTech ? jobInfo?.secondTech : "---",
    },
    // {
    //   label: t("job:dateRecord"),
    //   value: moment(jobInfo?.deliveryDate).format("DD/MM/YYYY")
    //     ? moment(jobInfo?.deliveryDate).format("DD/MM/YYYY")
    //     : "---",
    // },
  ];
  const schema = yup.object({
    centralizer: yup.boolean(),
    lotNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    lengthVal: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    casingNo: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    casingName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    setFocus,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const calculateWmud = (joi) => {
    let wei;
    let flo = jobInfo?.flotation ? jobInfo?.flotation : 0;
    if (selectedCasing?.weight) {
      wei = selectedCasing?.weight ? selectedCasing?.weight : 0;
    } else {
      wei = joi?.lot?.weight ? joi?.lot?.weight : 0;
    }
    const res = Number(joi?.lengthVal) * Number(wei) * Number(flo) * 0.00148;
    return res.toFixed(2);
  };
  const calculateLen = (index) => {
    let tot = 0;
    joints?.map((j, i) => {
      if (index >= i) {
        tot += Number(j.lengthVal);
      }
    });
    return tot;
  };
  const onSubmit = (data) => {
    const sameCasingName = joints.filter((j, i) => {
      return (
        String(j?.casingName).toLocaleLowerCase() ==
        String(data?.casingName).toLocaleLowerCase()
      );
    });
    if (sameCasingName.length > 0) {
      alert.success(t("job:sameName"), {
        position: positions.TOP_LEFT,
      });
      setValue("casingName", "");
    } else {
      data.wMud = Number(calculateWmud(data)).toFixed(2);
      setJoints((joints) => [...joints, data]);
      setSelectedCasing(selectedCasing);
      setValue("lengthVal", "");
      if (data.centralizer) {
        setValue("centralizer", true);
      }
    }
  };
  const handleJoints = async () => {
    await jointService
      .listJointsByJob(selectedJob.id)
      .then((res) => {
        setJoints(res.data.sort((a, b) => a?.casingNo - b?.casingNo));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNodes = async () => {
    await userService
      .listNodesByUser()
      .then((res) => {
        setNodes(res.data.node);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const calculateCasingUp = (index) => {
    let tot = jobInfo?.wellDepth ? jobInfo?.wellDepth : 0;
    joints?.map((j, i) => {
      if (index >= i) {
        tot -= Number(j.lengthVal);
      }
    });
    return tot;
  };
  const calculateMud = () => {
    let tot = 0;
    const values = joints?.map((j, i) => {
      if (Number(j.casingNo) >= i) {
        return Number((tot += Number(calculateWmud(j)))).toFixed(2);
      }
    });
    setWMuds(values);
  };
  useEffect(() => {
    if (joints?.length > 0) {
      const mapper = joints
        ?.map((j) => {
          if (Number(j.casingName)) {
            return j.casingName;
          } else {
            return null;
          }
        })
        ?.filter((n) => {
          return n !== null;
        })
        ?.reverse();
      const lastCasingName = mapper[0] ? mapper[0] : 0;
      setValue("casingName", Number(lastCasingName) + 1);
    }
    calculateWmud();
    calculateMud();
  }, [joints]);
  useEffect(() => {
    if (selectedJob?.id) {
      if (selectedJob?.latitude && selectedJob?.longitude) {
        setCoordinates([selectedJob?.latitude, selectedJob?.longitude]);
      }
      setJoints(selectedJob?.joint?.sort((a, b) => a?.casingNo - b?.casingNo));
      setSelectedNode(selectedJob?.node?.id);
    } else {
    }
  }, [selectedJob]);
  useEffect(() => {
    setValue("lotNo", selectedCasing.lotNo);
  }, [selectedCasing]);
  useEffect(() => {
    handleNodes();
  }, []);
  const createJob = async () => {
    setLoading(true);
    jobInfo.joint = joints;
    jobInfo.lotData = lots;
    jobInfo.coordinates = coordinates;
    jobInfo.node = selectedNode;
    if (selectedJob && selectedJob.id) {
      await jobService
        .updateJob(selectedJob?.id, jobInfo)
        .then((res) => {
          if (res.data.code == 0) {
            alert.success(t("job:updated"), {
              position: positions.TOP_LEFT,
            });
            setLoading(false);
            handleJobs();
            setNewJoint(false);
            setNewJob(false);
            setNewLot(false);
          } else {
            alert.error(t("job:failed"), {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      await jobService
        .createJob(jobInfo)
        .then((res) => {
          if (res.data.code == 0) {
            alert.success(t("job:saved"), {
              position: positions.TOP_LEFT,
            });
            setLoading(false);
            handleJobs();
            setNewJoint(false);
            setNewJob(false);
            setNewLot(false);
          } else {
            alert.error(t("job:failed"), {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      <div className="w-full h-full flex flex-col relative">
        <div
          style={{ zIndex: "0" }}
          className="flex flex-col items-start px-4 gap-6 w-full h-full"
        >
          <div className="flex flex-row items-center justify-between pl-5 h-14 border-b rounded-[2px] border-gray-200 w-full">
            <div className="flex items-center justify-center">
              <span className="font-medium text-xl text-black">
                {isMap
                  ? t("map:choose")
                  : selectedJob?.jobName
                  ? selectedJob?.jobName
                  : t("job:createNewJob")}
              </span>
            </div>
            <div>
              <button
                onClick={() => {
                  setQuickAddModal(true);
                }}
                className="w-[230px] xs:w-[160px] xs:h-8 h-10 rounded-lg bg-[#00727F] hover:border border-teal-500 flex items-center justify-center gap-2 p-2 text-sm xs:text-xs font-medium text-white"
              >
                <PlusIcon color="#FFFFFF" />
                {t("job:quickAdd")}
              </button>
            </div>
          </div>
          <div className="flex  flex-col items-start w-full h-[85%] xs:h-[77%]">
            {isMap ? (
              <Dashboard
                coordinates={coordinates}
                setCoordinates={setCoordinates}
                isLite={true}
              />
            ) : (
              <div className="flex flex-col xs:overflow-x-scroll  items-start w-full h-full border shadow rounded-lg">
                <div className="flex flex-row items-center gap-2 w-full h-[72px] px-6">
                  <p className="font-medium text-gray-900 text-lg">
                    {t("casing:casingTally")}
                  </p>
                  <span className="w-[66px] h-[22px] rounded-2xl bg-[#EDF7F8] text-xs flex items-center justify-center text-[#005F6A]">
                    {joints?.length} {t("job:joint")}
                  </span>
                </div>
                <div className="flex flex-col justify-center xs:min-w-[1440px] items-start w-full gap-3 h-[114px] border-b rounded-[2px]">
                  <div className="flex flex-wrap py-4 w-full">
                    {upperTable?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex flex-col items-start  border-r gap-2 w-[calc(100%/5)] h-[50%]  ${
                            index > 4 ? "" : " border-b rounded"
                          }  
                    `}
                        >
                          <div className="flex flex-row items-start justify-between py-2 px-3 w-full">
                            <p className="text-xs text-secondary-700">
                              {data?.label}
                            </p>
                            <p className="text-xs text-secondary-700 font-medium">
                              {data?.value}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-full h-11 xs:min-w-[1440px]  bg-gray-50 flex items-center justify-between">
                  {tableHeadItems?.map((d, i) => {
                    return (
                      <div
                        key={i}
                        className={`${
                          d?.value === "lotNo"
                            ? "w-[calc(100%/5)]"
                            : "w-[calc(100%/10)]"
                        } text-xs justify-center text-center h-full border-r font-medium text-gray-500 flex gap-1.5 items-center`}
                      >
                        {d?.label}
                      </div>
                    );
                  })}
                </div>
                {loading ? (
                  <div className="w-full h-full flex items-center justify-center">
                    <div className="w-12 h-12">
                      <Loading />
                    </div>
                  </div>
                ) : (
                  <div className="flex mb-6 flex-col items-start w-full xs:min-w-[1440px] h-full overflow-y-scroll overflow-hidden scrollbar-hide">
                    {joints?.map((b, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            className="flex min-h-[72px] w-full h-[72px] items-center border-b rounded"
                          >
                            <div className="text-xs w-[calc(100%/10)] h-full border-r justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                              #{i + 1}
                            </div>
                            <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                              <label class="relative inline-flex items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={b?.centralizer}
                                  className="sr-only peer"
                                  disabled
                                />
                                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-teal-700 peer-checked:bg-teal-700" />
                              </label>
                            </div>
                            <button className="text-xs  xs:text-sm w-[calc(100%/5)] justify-center items-center text-center h-full border-r  font-medium text-gray-500 flex gap-1.5">
                              {createLotName(b)}
                            </button>
                            <div className="text-xs  h-full border-r  w-[calc(100%/10)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                              {b?.casingNo}
                            </div>
                            <div className="text-xs  h-full border-r  w-[calc(100%/10)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                              {b?.casingName}
                            </div>
                            <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                              {Number(b?.lengthVal).toFixed(2)}
                            </div>
                            <div className="text-xs w-[calc(100%/10)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                              {calculateLen(i).toFixed(2)}
                            </div>
                            <div className="text-xs w-[calc(100%/10)] justify-center font-medium  h-full border-r  text-gray-500 flex gap-1.5 items-center">
                              {calculateCasingUp(i).toFixed(2)}
                            </div>
                            <div className="text-xs  h-full border-r  w-[calc(100%/10)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                              {b?.wMud}
                            </div>
                            <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                              {wMuds[i]}
                            </div>
                            <div className="w-[calc(100%/10)] justify-center flex gap-1.5 items-center">
                              <button className=" w-8  h-8 flex flex-row items-center rounded justify-center bg-gray-200">
                                <EditIcon
                                  onClick={() => {
                                    setSelectedJoint(b);
                                    setEditJointModal(true);
                                  }}
                                  width={16}
                                  height={16}
                                />
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex  w-full h-[72px] items-center border-b">
                        <div className="text-xs w-[calc(100%/10)]  h-full border-r justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                          #{joints?.length + 1}
                        </div>
                        <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                          <label class="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value={true}
                              disabled={joints?.length == 0 ? true : false}
                              {...register("centralizer")}
                              className="sr-only peer"
                            />
                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-teal-700 peer-checked:bg-teal-700" />
                          </label>
                        </div>
                        <Controller
                          control={control}
                          className
                          render={({ field: { onChange, onBlur, value } }) => (
                            <div className="text-xs text-ellipsis w-[calc(100%/5)] h-full border-r  font-medium text-gray-500 flex gap-1.5 relative items-center justify-center mt-2">
                              <Select
                                className={
                                  "border-solid border-y-0 border-x-0 border-b rounded border-secondary-300"
                                }
                                items={casingItems}
                                width={"90%"}
                                fontSize={12}
                                onChange={(v) => {
                                  setSelectedCasing(
                                    lots.filter((c, i) => {
                                      return c.lotNo == v;
                                    })[0]
                                  );
                                }}
                                value={value}
                                validate={errors?.lotNo ? "error" : ""}
                                errorMessage={
                                  errors?.lotNo ? errors?.lotNo?.message : ""
                                }
                              />
                            </div>
                          )}
                          name="lotNo"
                        />
                        <div className="text-xs w-[calc(100%/10)]  h-full border-r justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                          {joints?.length + 1}
                        </div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <div className=" text-xs w-[calc(100%/10)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                              <input
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                validate={errors?.casingName ? "error" : ""}
                                errorMessage={
                                  errors?.casingName
                                    ? errors?.casingName?.message
                                    : ""
                                }
                                className={`w-full mx-3 px-2 py-0.5 focus:outline-0 border-b rounded ${
                                  errors.casingName
                                    ? "border-red-500"
                                    : "border-secondary-300"
                                }  text-center`}
                              />
                            </div>
                          )}
                          name="casingName"
                        />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <div className=" text-xs w-[calc(100%/10)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                              <input
                                onChange={onChange}
                                onBlur={(e) => {
                                  calculateWmud(e.target.value);
                                }}
                                value={value}
                                validate={errors?.lengthVal ? "error" : ""}
                                errorMessage={
                                  errors?.lengthVal
                                    ? errors?.lengthVal?.message
                                    : ""
                                }
                                className={`w-full mx-3 px-2 py-0.5 focus:outline-0 border-b rounded ${
                                  errors.lengthVal
                                    ? "border-red-500"
                                    : "border-secondary-300"
                                }  text-center`}
                              />
                            </div>
                          )}
                          name="lengthVal"
                        />
                        <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                          <input
                            type={"text"}
                            className={
                              "w-full mx-3 px-2 py-0.5 focus:outline-0 border-secondary-300 text-center"
                            }
                            disabled
                            value={0}
                          />
                        </div>
                        <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                          <input
                            type={"text"}
                            className={
                              "w-full mx-3 px-2 py-0.5 focus:outline-0 border-secondary-300 text-center"
                            }
                            disabled
                            value={0}
                          />
                        </div>
                        <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                          <input
                            type={"text"}
                            className={
                              "w-full mx-3 px-2 py-0.5 focus:outline-0 border-secondary-300 text-center"
                            }
                            disabled
                            value={0}
                          />
                        </div>
                        <div className="text-xs w-[calc(100%/10)]  h-full border-r  justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                          <input
                            type={"text"}
                            className={
                              "w-full mx-3 px-2 py-0.5 focus:outline-0  border-secondary-300 text-center"
                            }
                            disabled
                            value={0}
                          />
                        </div>
                        <div className="w-[calc(100%/10)] justify-center flex gap-1.5 items-center">
                          <button
                            type="submit"
                            onClick={() => {
                              setValue("casingNo", joints.length + 1);
                            }}
                            className=" w-8  h-8 flex flex-row items-center rounded justify-center bg-[#D1FADF]"
                          >
                            <CheckIcon
                              width={16}
                              height={16}
                              color={"#027A48"}
                            />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row justify-end items-center gap-6 w-full">
            <button
              onClick={() => setNewJoint(false)}
              className="flex flex-row items-center justify-center w-[144px] h-[40px] bg-white shadow border rounded-lg text-gray-700 text-sm "
            >
              {t("buttons:stop")}
            </button>
            <button
              onClick={() => {
                if (!isMap) {
                  setIsMap(true);
                } else {
                  if (auth.user.company.id == '66cbd62a-4295-4343-beb3-4bf129ac4e23') {
                    createJob();
                  } else {
                    setChooseNodeModal(true);
                  }
                }
              }}
              className="flex flex-row items-center justify-center w-[144px] h-[40px] bg-[#00727F] shadow border rounded-lg text-white text-sm "
            >
              {isMap
                ? selectedJob
                  ? t("buttons:edit")
                  : t("buttons:add")
                : t("buttons:next")}
            </button>
          </div>
        </div>
        <UpdateJointModal
          modalIsOpen={editJointModal}
          closeModal={() => setEditJointModal(false)}
          setEditJointModal={setEditJointModal}
          selectedJoint={selectedJoint}
          handleJoints={handleJoints}
          jobInfo={jobInfo}
          casingItems={casingItems}
          lots={lots}
          joints={joints}
          setJoints={setJoints}
          title={t("job:updateJoint")}
        />
        <ChooseNodeModal
          isOpen={chooseNodeModal}
          setIsOpen={setChooseNodeModal}
          nodes={nodes}
          createJob={createJob}
          setSelectedNode={setSelectedNode}
          selectedNode={selectedNode}
        />
        <QuickAddModal
          lots={lots}
          isOpen={quickAddModal}
          setIsOpen={setQuickAddModal}
          jobInfo={jobInfo}
          setJoints={setJoints}
          joints={joints}
          setSelectedCasing={setSelectedCasing}
          selectedCasing={selectedCasing}
        />
      </div>
    </>
  );
};

export default CreateNewJobStep2;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Chart from "./Charts";
import moment from "moment";
import { CloseIcon } from "../../assets/icons";
import { useStore } from "../../hooks/useStores";
const JointChartModal = ({
  modalIsOpen,
  title = "Chart Joint",
  width,
  closeModal,
  height,
  i,
  selectedJoint,
  setIsOpen,
  jobData,
  tableData,
}) => {
  const { report } = useStore();
  const { t } = useTranslation();
  const [jointComment, setJointComment] = useState("");
  const updateJointData = () => {
    const filter = tableData?.map((t, i) => {
      return t?.filter((subArr, i) => {
        return subArr.JAMJointN == selectedJoint?.pageData?.jointNumber;
      });
    });
    const mapper = filter?.flat()[0];
    const connectedLot = jobData?.filter((data, i) => {
      return data?.lotData?.lotNO == selectedJoint?.pageData?.jointLotNo;
    })[0];
    const foundedJoint = connectedLot?.value?.filter((data, i) => {
      return data?.tableData?.JAMJointN == selectedJoint?.pageData?.jointNumber;
    })[0];
    if (foundedJoint && mapper) {
      foundedJoint.pageData.jointComment = jointComment;
      mapper.jointComment = jointComment;
    }
    report.setReportData(report.reportData);
  };
  return (
    <>
      <Modal
        appElement={document.getElementById("app")}
        closeTimeoutMS={350}
        isOpen={modalIsOpen}
        onRequestClose={() => {
          closeModal();
          updateJointData();
        }}
        className={`h-[650px] w-[1108px] ReactModal`}
        style={{
          overlay: {
            background: "rgba(0, 0, 0, 0.3)",
          },
          content: {
            position: "absolute",
            transform: "translate(-50%,-50%)",
            width: width,
            left: "50%",
            top: "50%",
            background: "#fff",
            height: height,
            padding: "0px",
            boxShadow:
              "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
            borderRadius: "12px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "20px",
            margin: "0px",
          },
        }}
      >
        <div className="h-[650px] w-[1120px] flex flex-col justify-center items-center overflow-y-scroll">
          <Chart
            //report info
            revNo={"R1"}
            reportDate={moment().utcOffset("+0300").format("DD/MM/YYYY-HH:mm")}
            pageNo={selectedJoint?.pageNumber}
            //chart info
            torkChartDtaXAxis={selectedJoint?.torkChart?.xAxis}
            torkChartDta={selectedJoint?.torkChart?.data}
            rpmChartData={selectedJoint?.rpmChart?.data}
            rpmChartXAxis={selectedJoint?.rpmChart?.xAxis}
            //joint info
            startTime={selectedJoint?.pageData?.startTime}
            endTime={selectedJoint?.pageData?.endTime}
            sequanceNo={selectedJoint?.pageData?.seqNo}
            jointNo={selectedJoint?.pageData?.jointNumber}
            casingNo={selectedJoint?.pageData?.casingNo}
            casingName={selectedJoint?.pageData?.casingName}
            jointAprt={selectedJoint?.pageData?.jointAprt}
            jointAprtLen={selectedJoint?.pageData?.jointAprtLen}
            jointSpecific={selectedJoint?.pageData?.jointSpecific}
            jointLen={selectedJoint?.pageData?.jointLen}
            jointTotalLen={selectedJoint?.pageData?.jointTotalLen}
            jointRemainingLen={selectedJoint?.pageData?.jointRemainingLen}
            jointMud={selectedJoint?.pageData?.jointMud}
            jointTMud={selectedJoint?.pageData?.jointTMud}
            comment={selectedJoint?.pageData?.jointComment}
            fTork={selectedJoint?.pageData?.finalTork}
            dTork={selectedJoint?.pageData?.dTork}
            fTurn={selectedJoint?.pageData?.fTurn}
            turnShld={selectedJoint?.pageData?.sTurn}
            dTurn={selectedJoint?.pageData?.dTurn}
            maxRpm={selectedJoint?.pageData?.maxRpm}
            sRpm={selectedJoint?.pageData?.sRpm}
            clockTime={selectedJoint?.pageData?.startTime}
            duration={
              Number(selectedJoint?.pageData?.endTime) -
              Number(selectedJoint?.pageData?.startTime)
            }
            min={
              Number(selectedJoint?.tableData?.minTork)
                ? Number(selectedJoint?.tableData?.minTork)
                : Number(selectedJoint?.pageData?.minTork)
            }
            max={
              Number(selectedJoint?.tableData?.maxTork)
                ? Number(selectedJoint?.tableData?.maxTork)
                : Number(selectedJoint?.pageData?.maxTork)
            }
            opt={
              Number(selectedJoint?.tableData?.optTork)
                ? Number(selectedJoint?.tableData?.optTork)
                : Number(selectedJoint?.pageData?.optTork)
            }
            maxTurn={
              Number(selectedJoint?.tableData?.maxTurn)
                ? Number(selectedJoint?.tableData?.maxTurn)
                : Number(selectedJoint?.pageData?.maxTurn)
            }
            minTurn={
              Number(selectedJoint?.tableData?.minTurn)
                ? Number(selectedJoint?.tableData?.minTurn)
                : Number(selectedJoint?.pageData?.minTurn)
            }
            shoulderTurn={selectedJoint?.pageData?.sTurn}
            torqueShld={selectedJoint?.pageData?.shoTork}
            status={selectedJoint?.pageData?.status}
            //make up info
            depthTally={
              selectedJoint?.pageData?.jointTotalLen &&
                report?.reportData?.coverPageData?.wellDepth
                ? `${Number(selectedJoint?.pageData?.jointTotalLen).toFixed(
                  2
                )}/${Number(
                  report?.reportData?.coverPageData?.wellDepth
                ).toFixed(2)}`
                : "-"
            }
            makeUpStatus={selectedJoint?.pageData?.status}
            //casing equipment info
            threadType={selectedJoint?.pageData?.threadType}
            threadCompond={selectedJoint?.pageData?.threadCompound}
            casingSize={selectedJoint?.pageData?.pipeSize}
            casingWeight={selectedJoint?.pageData?.pipeWeight}
            casingGrade={selectedJoint?.pageData?.pipeGrade}
            cellCapacity={selectedJoint?.pageData?.cellCapacity}
            cellSensitivity={selectedJoint?.pageData?.cellSensitivity}
            armLength={selectedJoint?.pageData?.armLenght}
            connectionInfo={selectedJoint?.pageData?.connectionInfo}
            jointComment={jointComment}
            setJointComment={setJointComment}
            jamVersion={report?.reportData?.jamVersion}
            closeModal={() => {
              closeModal();
              updateJointData();
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default JointChartModal;
